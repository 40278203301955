import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components'


import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { Container } from "../components/layout/layoutComponents"
import { typo, colors } from '../components/utils'

const SimplePage= styled.article`
  max-width: 1140px;
  margin: 0 auto;
  padding: 120px 35px;
  font-size: 20px;
  h1 {
    font-size: 60px;
    font-weight: ${typo.bold};
    line-height: ${typo.lineheadline};
    color: ${colors.default};
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 40px;
    font-weight: ${typo.bold};
    line-height: ${typo.linesubline};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 40px;
    font-weight: ${typo.bold};
    line-height: ${typo.linesubline};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 15px;
  }
`
const ArticleText = styled.div`
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    margin: 0;
    margin-bottom: 40px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    list-style: none;
    li {
      position: relative;
      margin-bottom: 15px;
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: -39px;
        height: 13px;
        width: 13px;
        background-color: ${colors.orange};
      }
    }  
  }
`

const Disclaimer = () => (
  <StaticQuery
    query={graphql`
    query {
      craftDisclaimerDisclaimerEntry {
        id
        slug
        title
        article
      }
     }
    `}

    render={({ craftDisclaimerDisclaimerEntry }) => (
  
  <Layout>
    <SEO title="Disclaimer" />
    <Container>
    <SimplePage>
        <ArticleText dangerouslySetInnerHTML={{ __html: craftDisclaimerDisclaimerEntry.article }} />
    </SimplePage>
    </Container>
  </Layout>
    )}
  />
)

export default Disclaimer;